import { AST_ACTIONS, AST_COOLDOWNS, AST_RESOURCES, AST_TRAITS } from "./Jobs/AST";
import { BLM_ACTIONS, BLM_COOLDOWNS, BLM_RESOURCES, BLM_TRAITS } from "./Jobs/BLM";
import { BRD_ACTIONS, BRD_COOLDOWNS, BRD_RESOURCES, BRD_TRAITS } from "./Jobs/BRD";
import { DNC_ACTIONS, DNC_COOLDOWNS, DNC_RESOURCES, DNC_TRAITS } from "./Jobs/DNC";
import { DRG_ACTIONS, DRG_COOLDOWNS, DRG_RESOURCES, DRG_TRAITS } from "./Jobs/DRG";
import { DRK_ACTIONS, DRK_COOLDOWNS, DRK_RESOURCES, DRK_TRAITS } from "./Jobs/DRK";
import { GNB_ACTIONS, GNB_COOLDOWNS, GNB_RESOURCES, GNB_TRAITS } from "./Jobs/GNB";
import { MCH_ACTIONS, MCH_COOLDOWNS, MCH_RESOURCES, MCH_TRAITS } from "./Jobs/MCH";
import { MNK_ACTIONS, MNK_COOLDOWNS, MNK_RESOURCES, MNK_TRAITS } from "./Jobs/MNK";
import { NIN_ACTIONS, NIN_COOLDOWNS, NIN_RESOURCES, NIN_TRAITS } from "./Jobs/NIN";
import { PCT_ACTIONS, PCT_COOLDOWNS, PCT_RESOURCES, PCT_TRAITS } from "./Jobs/PCT";
import { PLD_ACTIONS, PLD_COOLDOWNS, PLD_RESOURCES, PLD_TRAITS } from "./Jobs/PLD";
import { RDM_ACTIONS, RDM_COOLDOWNS, RDM_RESOURCES, RDM_TRAITS } from "./Jobs/RDM";
import { RPR_ACTIONS, RPR_COOLDOWNS, RPR_RESOURCES, RPR_TRAITS } from "./Jobs/RPR";
import { SAM_ACTIONS, SAM_COOLDOWNS, SAM_RESOURCES, SAM_TRAITS } from "./Jobs/SAM";
import { SCH_ACTIONS, SCH_COOLDOWNS, SCH_RESOURCES, SCH_TRAITS } from "./Jobs/SCH";
import { SGE_ACTIONS, SGE_COOLDOWNS, SGE_RESOURCES, SGE_TRAITS } from "./Jobs/SGE";
import { SMN_ACTIONS, SMN_COOLDOWNS, SMN_RESOURCES, SMN_TRAITS } from "./Jobs/SMN";
import { VPR_ACTIONS, VPR_COOLDOWNS, VPR_RESOURCES, VPR_TRAITS } from "./Jobs/VPR";
import { WAR_ACTIONS, WAR_COOLDOWNS, WAR_RESOURCES, WAR_TRAITS } from "./Jobs/WAR";
import { WHM_ACTIONS, WHM_COOLDOWNS, WHM_RESOURCES, WHM_TRAITS } from "./Jobs/WHM";
import { COMMON_ACTIONS, COMMON_COOLDOWNS, COMMON_RESOURCES, COMMON_TRAITS } from "./Shared/Common";
import {
	LIMIT_BREAK_ACTIONS,
	LIMIT_BREAK_COOLDOWNS,
	LIMIT_BREAK_RESOURCES,
} from "./Shared/LimitBreak";
import { ROLE_ACTIONS, ROLE_COOLDOWNS, ROLE_RESOURCES, ROLE_TRAITS } from "./Shared/Role";

export const ACTIONS = {
	...COMMON_ACTIONS,
	...ROLE_ACTIONS,
	...LIMIT_BREAK_ACTIONS,

	// Tanks
	...PLD_ACTIONS,
	...WAR_ACTIONS,
	...DRK_ACTIONS,
	...GNB_ACTIONS,

	// Healers
	...WHM_ACTIONS,
	...SCH_ACTIONS,
	...AST_ACTIONS,
	...SGE_ACTIONS,

	// Melee
	...MNK_ACTIONS,
	...DRG_ACTIONS,
	...NIN_ACTIONS,
	...SAM_ACTIONS,
	...RPR_ACTIONS,
	...VPR_ACTIONS,

	// Ranged
	...BRD_ACTIONS,
	...MCH_ACTIONS,
	...DNC_ACTIONS,

	// Casters
	...BLM_ACTIONS,
	...SMN_ACTIONS,
	...RDM_ACTIONS,
	...PCT_ACTIONS,
};

export const COOLDOWNS = {
	...COMMON_COOLDOWNS,
	...ROLE_COOLDOWNS,
	...LIMIT_BREAK_COOLDOWNS,

	// Tanks
	...PLD_COOLDOWNS,
	...WAR_COOLDOWNS,
	...DRK_COOLDOWNS,
	...GNB_COOLDOWNS,

	// Healers
	...WHM_COOLDOWNS,
	...SCH_COOLDOWNS,
	...AST_COOLDOWNS,
	...SGE_COOLDOWNS,

	// Melee
	...MNK_COOLDOWNS,
	...DRG_COOLDOWNS,
	...NIN_COOLDOWNS,
	...SAM_COOLDOWNS,
	...RPR_COOLDOWNS,
	...VPR_COOLDOWNS,

	// Ranged
	...BRD_COOLDOWNS,
	...MCH_COOLDOWNS,
	...DNC_COOLDOWNS,

	// Casters
	...BLM_COOLDOWNS,
	...SMN_COOLDOWNS,
	...RDM_COOLDOWNS,
	...PCT_COOLDOWNS,
};

export const RESOURCES = {
	...COMMON_RESOURCES,
	...ROLE_RESOURCES,
	...LIMIT_BREAK_RESOURCES,

	// Tanks
	...PLD_RESOURCES,
	...WAR_RESOURCES,
	...DRK_RESOURCES,
	...GNB_RESOURCES,

	// Healers
	...WHM_RESOURCES,
	...SCH_RESOURCES,
	...AST_RESOURCES,
	...SGE_RESOURCES,

	// Melee
	...MNK_RESOURCES,
	...DRG_RESOURCES,
	...NIN_RESOURCES,
	...SAM_RESOURCES,
	...RPR_RESOURCES,
	...VPR_RESOURCES,

	// Ranged
	...BRD_RESOURCES,
	...MCH_RESOURCES,
	...DNC_RESOURCES,

	// Casters
	...BLM_RESOURCES,
	...SMN_RESOURCES,
	...RDM_RESOURCES,
	...PCT_RESOURCES,
};

export const TRAITS = {
	...COMMON_TRAITS,
	...ROLE_TRAITS,

	// Tanks
	...PLD_TRAITS,
	...WAR_TRAITS,
	...DRK_TRAITS,
	...GNB_TRAITS,

	// Healers
	...WHM_TRAITS,
	...SCH_TRAITS,
	...AST_TRAITS,
	...SGE_TRAITS,

	// Melee
	...MNK_TRAITS,
	...DRG_TRAITS,
	...NIN_TRAITS,
	...SAM_TRAITS,
	...RPR_TRAITS,
	...VPR_TRAITS,

	// Ranged
	...BRD_TRAITS,
	...MCH_TRAITS,
	...DNC_TRAITS,

	// Casters
	...BLM_TRAITS,
	...SMN_TRAITS,
	...RDM_TRAITS,
	...PCT_TRAITS,
};

export type Actions = typeof ACTIONS;
export type ActionKey = keyof Actions;

export type Cooldowns = typeof COOLDOWNS;
export type CooldownKey = keyof Cooldowns;

export type Resources = typeof RESOURCES;
export type ResourceKey = keyof Resources;

export type Traits = typeof TRAITS;
export type TraitKey = keyof Traits;
